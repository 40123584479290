<template>
  <div class="auth-form justify-align-center col-gap-3">
    <slot name="caption">
      <template>
        <h2>Авторизация за 5 секунд</h2>
        <h3 class="mt-3">Для создания заказа необходимо войти в кабинет</h3>
        <h5 class="mt-10">Войти</h5>
      </template>
    </slot>
    <LoginTelegramAuthorize />
    <div class="justify-align-center gap-1">
      или
      <span
        class="button-link"
        @click="$emit('setMode', LoginMode.REGISTER_EMAIL)"
        >через email</span
      >
    </div>

    <p class="font-semibold text-[10px] leading-[15px] text-center text-[rgba(27,28,30,0.40)]">
      Продолжая, вы соглашаетесь с
      <a
        href="/pdf/oferta.pdf"
        rel="noopener noreferrer"
        target="_blank"
        class="underline"
        >офертой</a
      >
      и
      <a
        href="/pdf/politic.pdf"
        rel="noopener noreferrer"
        target="_blank"
        class="underline"
        >политикой в отношении обработки персональных данных</a
      >
    </p>
  </div>
</template>
